@import url('../../assets/css-colors/colors.module.css');

.container {
    display: flex;

}

.row {
    justify-content: center;


}

.row>div:first-of-type {
    display: flex;
    justify-content: flex-start;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;

}



.image>img {
    width: 70%;
    object-fit: contain;

}