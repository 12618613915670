@import url('../../assets/css-colors/colors.module.css');


.text-container {
    margin-left: 3rem;

}

.title {
    color: var(--yellow);
    font-size: 2vmax;
    text-transform: uppercase;
}

.description {
    font-size: 1.2vmax;
    color: white;
}

.description::first-letter {
    text-transform: capitalize;
}


.concluding {
    display: block;
    color: var(--yellow);
    font-weight: bold;
    font-size: 1.5vmax;
    text-transform: lowercase;
    text-align: right;
    margin: 0;
}

@media (max-width: 576px) {
    .title {
        font-size: 3vmax;
    }

    .description {
        font-size: 2vmax;
    }

    .concluding {
        font-size: 2.5vmax;
    }
}