@import url('../../assets/css-colors/colors.module.css');



.UIButton {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    font-size: 0.875rem;
    width: max-content;
    padding: 0;
}

.UIButton>a {
    color: #fff;
    font-size: 1vmax;
}

.UIButton>a:hover {
    color: #fff;
}





.UIButton:hover, .UIButton:visited {
    background-color: var(--yellow);
    color: black;
}



@media (max-width: 992px) {
    .UIButton {
        width: 100%;
        text-align: start;
        padding-left: 0.75rem;
    }
}