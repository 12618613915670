@import '../../assets/css-colors/colors.module.css';

.models-container {
    color: var(--yellow);

}

.models-parent-container {
    flex-direction: column;
    margin-top: 3rem;
}


.models-children-container {
    flex-direction: row;
}

.models-children-container {
    padding: 0;

}

.models-children-container>div:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}



.models-children-container img {
    pointer-events: none;
    width: 30vmax;
}

@media (min-width: 576px) {
    .models-children-container img {
        width: 20vmax;
    }
}