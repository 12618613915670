@import url('../../assets/css-colors/colors.module.css');



a {
    text-decoration: none;
}

.active {
    color: var(--yellow);
    text-decoration: none;
}

.navigationBar>nav button {

    margin-left: 1vw;
}

.navigationBar>nav>div a {
    font-size: 1vmax;
}

.navigationBar {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.navigationBar>nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.navigationBar>nav div>button>a.active {
    color: var(--yellow);
    text-decoration: none;
}

.navigationBar>nav>div>button {
    color: var(--gray);
    background-color: var(--yellow);
}


.navigationBar>nav>div>a>img {
    width: 5vw;
}



@media (max-width: 992px) {
    .navigationBar>nav>div>a>img {
        width: 6vw;
    }
}

@media (max-width: 768px) {
    .navigationBar>nav button {
        margin-top: 2vw;
    }

    .navigationBar>nav>div a {
        font-size: 1.5vmax;
    }
}

@media (max-width: 576px) {
    .navigationBar>nav button {
        margin-top: 2.5vw;
    }

    .navigationBar>nav>div>a>img {
        width: 8vw;
    }
}



@media (max-width: 425px) {
    .navigationBar>nav button {

        margin-top: 3vw;
    }

    .navigationBar>nav>div a {
        font-size: 2vmax;
    }
}