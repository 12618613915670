@import url('../../assets/css-colors/colors.module.css');

.philosophy-text-container>div {
    margin: 3rem 0 3rem 0;
}



.philosophy-image-container div p {
    color: var(--yellow);
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0.2rem;
    font-size: 1vmax;
}