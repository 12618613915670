@import url('../../assets/css-colors/colors.module.css');

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
}

.carousel-container>img {
    position: absolute;
    width: 20%;
    z-index: 100;
}

.carousel {}

.bison-heritage-container {
    display: flex;
    background-image: url('../../assets/background-images/Bison-background-562149.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.bison-heritage-container .row {
    justify-content: center;


}

.bison-heritage-container .row>div:first-of-type {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.bison-heritage-container .row>div:first-of-type>img {
    margin-left: 3rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: solid 0.2rem;
    border-color: var(--yellow);
}

.bison-heritage-image {
    display: flex;
    justify-content: center;
    align-items: center;
}



.image>img {
    object-fit: contain;
    width: 70%;
}

.accordion-container {

    background-image: linear-gradient(to bottom,
            rgba(27, 43, 85, 0.8) 60%,
            rgba(27, 43, 85, 1)), url('../../assets/background-images/bg-blue-559033.jpg');

}


.accordion {
    --bs-accordion-border-color: var(--yellow);
    --bs-accordion-bg: transparent;
}


.accordion>div {
    border: 1px solid var(--yellow);
}

.accordion-header h1 {
    font-size: 1.5vmax;
    margin: 0;
}

.accordion-header button {
    background-color: var(--blue);
    color: var(--yellow);
    --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23c48946%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>');

}

.accordion-header button:not(.collapsed) {
    color: var(--yellow);
    background-color: var(--blue);
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23c48946%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>')
}

.accordion-body {
    background-color: var(--blue);
    color: #fff;
}

.accordion-body p {
    font-size: 1vmax;
}


.accordion-body h3 {
    font-size: 1.2vmax;
    color: var(--yellow);
}

.accordion-image-container {
    height: 20vmax;
    padding: 0;
}

.accordion-image-container>img {
    object-fit: cover;
    height: 100%;
}

.gallery-container {
    position: relative;
    margin: 0;
    padding: 0;

}

.gallery-container>div {
    margin: 0;
    padding: 0;

}

.gallery-container>div div {
    padding: 0;
    margin: 0;
    display: flex;
    max-height: 30vmin;
    opacity: 0.2;
}


.gallery-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    pointer-events: none;
}

.gallery-text {
    position: absolute;
    border: 2px solid var(--yellow);
    text-align: center;
    top: 43%;
    left: 43%;
    color: var(--yellow);
}

.gallery-text:hover {
    border-color: #fff;
    color: #fff;

}

.gallery-text p {
    margin: 1vmax 2.5vmax 1vmax 2.5vmax;
    font-size: 1.5vmax;
    text-transform: uppercase;

}



@media (max-width: 1400px) {
    .accordion-image-container {
        height: 25vmax;
    }

    .accordion-header h1 {
        font-size: 1.5vmax;
    }

    .accordion-body p {
        font-size: 1vmax;
    }

    .accordion-body h3 {
        font-size: 1.3vmax;
    }
}

@media (max-width: 992px) {
    .accordion-image-container {
        height: 25vmax;
    }

    .accordion-header h1 {
        font-size: 1.8vmax;
    }

    .accordion-body p {
        font-size: 1.2vmax;
    }
}

@media (max-width: 768px) {
    .accordion-image-container {
        height: 35vmax;
    }

    .accordion-header h1 {
        font-size: 2vmax;
    }

    .accordion-body p {
        font-size: 2vmax;
    }

    .accordion-body h3 {
        font-size: 2.3vmax;
    }
}

@media (max-width: 425px) {


    .accordion-image-container {
        height: 30vmax;
    }

    .accordion-header h1 {
        font-size: 2.5vmax;
    }

    .accordion-body p {
        font-size: 1.8vmax;
    }

    .accordion-body h3 {
        font-size: 2vmax;
    }
}