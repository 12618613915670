.images-container {
    text-align: center;
}

.images-list {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

}

.images-item {
    /* max-width: 100%;
    max-height: 100%; */
}

.images-item img {
    width: 20vmax;
    height: 10vmax;
    object-fit: cover;
}

.images-item img:hover {
    border: 3px solid var(--yellow);
    cursor: zoom-in;
}