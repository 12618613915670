@import url('../../assets/css-colors/colors.module.css');

.footer a {
    color: #fff;
    font-size: 1vmax;
    text-decoration: underline;
}

.footer a:hover {
    color: var(--yellow);
}

.footer p {
    color: var(--gray);
    font-size: 1vmax;
}

.footer {
    margin-top: auto;
    background-image: url('../../assets/background-images/bg-560368.jpg');
}



.footer>div {
    margin: 5rem 0 5rem 0;
}

.footer i {
    color: var(--yellow);
    margin-right: 0.75rem;
}


.footer>div>div>h1 {
    color: var(--yellow);
    font-size: 1.5vmax;
    text-transform: uppercase;
}

.footer>div>div>h2 {
    color: #fff;
    font-size: 1.2vmax;
}

.footer>div>div>ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.footer>div>div>ul>li {
    font-size: 1vmax;
    margin-bottom: 0.75rem;

}

.footer>div>div>ul>li a {
    color: #fff;
}




.footer>div>div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.footer>div>div:first-of-type>img {
    width: 5rem;
}

@media (max-width: 768px) {
    .footer>div>div>h1 {

        font-size: 3vmax;

    }

    .footer>div>div>h2 {

        font-size: 2.2vmax;
    }

    .footer>div>div>ul>li {
        font-size: 2vmax;

    }

    .footer p {

        font-size: 2vmax;
    }

    .footer a {
        line-height: 3;
        font-size: 2vmax;
    }
}

@media (max-width: 425px) {
    .footer>div>div>h1 {

        font-size: 3vmax;

    }

    .footer>div>div>h2 {

        font-size: 2vmax;
    }

    .footer>div>div>ul>li {
        font-size: 1.5vmax;

    }

    .footer p {

        font-size: 2vmax;
    }

    .footer a {

        font-size: 2vmax;
        line-height: 3;
    }
}