@import url('../../assets/css-colors/colors.module.css');





button>i {
    font-size: 1.25rem;
    padding: 1rem;
    background-color: var(--yellow);
}

.back-to-top-btn {
    position: fixed;
    bottom: 0;
    left: 45%;
    padding: 0;
}

@media (max-width: 992px) {
    .back-to-top-btn {
        width: fit-content;
    }
}