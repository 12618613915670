@import url('../../assets/css-colors/colors.module.css');

.contact-container {
    color: #fff;
}

.contact-container h2, .contact-container h1, .contact-container i {
    color: var(--yellow);
}

.contact-container h1 {
    font-size: 3vmax;
}

.contact-container h2 {
    font-size: 2.5vmax;
}

.contact-container i {
    font-size: 2vmax;
}

.contact-container a {
    color: #fff;
    margin-left: 1rem;
    text-decoration: underline;
    font-size: 2vmax;
}

.contact-container a:hover {
    color: var(--yellow);
}

.contact-container li {
    list-style-type: none;
}

.contact-info-container>p {
    font-size: 2vmax;
}

.contact-info-grid {
    display: flex;
    flex-direction: column;
}

.contact-info-grid>div {
    padding-top: 1rem;
}

.contact-info-grid div>p {
    font-size: 2vmax;
}

.form-container label {
    font-size: 2vmax;
}

.form-container>form {
    margin-left: auto;
    margin-right: auto;
}

.form-container>form>div {
    margin-top: 1rem;
}

@media (min-width: 768px) {
    .contact-container h1 {
        font-size: 2vmax;
    }

    .contact-container h2 {
        font-size: 1.5vmax;
    }

    .contact-container i {
        font-size: 1vmax;
    }

    .contact-container a {
        font-size: 1vmax;
    }

    .contact-info-container>p {
        font-size: 1vmax;
    }

    .contact-info-grid>div {
        padding-top: 2rem;
    }

    .contact-info-grid div>p {
        font-size: 1vmax;
    }

    .form-container label {
        font-size: 1.2vmax;
    }
}